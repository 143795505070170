@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spinner-sm {
    @apply block border-0 border-s-2 border-t-4 border-s-accent border-t-accent mx-auto;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    animation: spin infinite 2s linear;
  }
  .spinner-sm-white {
    @apply block border-0 border-s-2 border-t-4 border-s-white border-t-white mx-auto;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    animation: spin infinite 2s linear;
  }
  .spinner-md {
    @apply block border-0 border-s-2 border-t-4 border-s-accent border-t-accent mx-auto;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    animation: spin infinite 2s linear;
  }
  .spinner-md-white {
    @apply block border-0 border-s-2 border-t-4 border-s-white border-t-white mx-auto;
    height:50px;
    width: 50px;
    border-radius: 50%;
    animation: spin infinite 2s linear;
  }
}
.form-control{
  @apply w-full border border-gray-300 py-2 px-3 rounded-md focus:border-transparent mb-0
}
button{
  @apply rounded-md p-2 my-2 text-white w-full cursor-pointer md:w-2/12
}
label{
  @apply mt-3
}
fieldset.w-100{
  width:100% !important
}
fieldset > div.w-100{
  width: 100% !important
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
